/* Created By MK */

@font-face {
  font-family: "Roobert";
  src: url("./Assets/Fonts/RoobertRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roobert";
  src: url("./Assets/Fonts/RoobertBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
}

p {
  font-family: "Roobert", sans-serif !important;
}
